import { css } from '@emotion/core'
import React from 'react'
import { Helmet } from 'react-helmet'
import blob from '../assets/blob.svg'
import hero from '../assets/hero.svg'
import human from '../assets/human.svg'
import statistic from '../assets/statistic.svg'
import { Box, Layout, Navbar } from '../components'

const heroSection = css`
  display: flex;
  margin: 0 auto;
  max-width: 60rem;
`

const heroTextWrapper = css`
  margin: 3rem 0 2rem 2rem;
`

const title = css`
  line-height: 1.2;
  font-size: 64px;
  font-weight: 600;
  color: #3525e6;
`

const heroText = css`
  margin-top: 1rem;
  font-size: 24px;
  font-weight: 400;
  color: #1d0ebe;
`

const secondSection = css`
  margin-top: 8rem;
  margin-bottom: 4rem;
  text-align: center;
`

const H2 = css`
  margin-bottom: 4rem;
  font-size: 56px;
  font-weight: 600;
  color: #0c008c;
`

const card = css`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 300px;
  height: 200px;
  box-shadow: 0px 3px 4px rgba(10, 31, 68, 0.1), 0px 0px 1px rgba(10, 31, 68, 0.08);
  border-radius: 4px;
  font-size: 24px;
  font-weight: 600;
  color: #1d0ebe;
`

const thirdSection = css`
  display: flex;
  justify-content: space-between;
  margin: 0 auto;
  max-width: 72rem;
`

const blobContent = css`
  margin-top: 7rem;
  margin-left: 6rem;
  position: absolute;
  width: 440px;
  font-size: 20px;
  font-weight: 600;
  color: #0c008c;
  text-align: center;
`

const blobCard = css`
  margin-top: 2rem;
  padding: 1rem;
  box-shadow: 0px 3px 4px rgba(10, 31, 68, 0.1), 0px 0px 1px rgba(10, 31, 68, 0.08);
  border-radius: 4px;
  font-size: 16px;
  font-weight: 500;
  color: #1d0ebe;
  background: white;
  text-align: left;
`

const fourthSection = css`
  display: flex;
  justify-content: space-between;
  margin: 0 auto;
  margin-top: 6rem;
  margin-bottom: 6rem;
  max-width: 72rem;
`

const exampleText = css`
  width: 350px;
  color: #0c008c;
  font-size: 24px;
  font-weight: 600;
  text-align: center;
`

const previewAd = css`
  display: block;
  margin: 2rem auto;
  width: 320px;
  height: 144px;
  border-radius: 4px;
  box-shadow: 0 8px 24px rgba(32, 43, 54, 0.12);
  text-decoration: none;
  color: white;
  background: linear-gradient(120deg, #4d3df7 0%, #8888fc 100%);
  &:after {
    position: absolute;
    border-bottom-left-radius: 2px;
    content: 'Ad by MakerTraffic';
    margin-top: -18px;
    font-family: sans-serif;
    font-size: 10px;
    background: rgba(0, 0, 0, 0.12);
    padding: 4px 6px;
    color: rgba(255, 255, 255, 0.75);
  }
`

const adTitle = css`
  font-weight: 600;
  display: flex;
  justify-content: center;
  align-items: flex-end;
  height: 40%;
  font-size: 32px;
`

const adDescription = css`
  font-size: 16px;
  font-weight: 500;
  padding: 0 1rem;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  height: 60%;
`

const footerSection = css`
  background-color: #e6e6ff;
`

const footerContent = css`
  margin: 0 auto;
  max-width: 56rem;
  display: flex;
  justify-content: space-between;
  padding: 3rem;
`

const footerTitle = css`
  font-size: 24px;
  font-weight: 600;
  color: #0c008c;
  margin-bottom: 0.5rem;
`

const footerLink = css`
  font-size: 16px;
  font-weight: 400;
  color: #1d0ebe;
  margin-bottom: 0.5rem;
  text-decoration: none;
  &:hover {
    text-decoration: underline;
    cursor: pointer;
  }
`

const codeText = `<iframe
  style="border:0;width:320px;height:145px;"
  src="https://api.makertraffic.com/servead/YOUR_TOKEN"
></iframe>
`

const IndexPage = () => (
  <Layout>
    <Helmet>
      <title>Maker Traffic - Ads platform for makers</title>
    </Helmet>
    <Navbar />
    <div css={heroSection}>
      <img src={hero} alt="computer screen and people interacting with analytics" />
      <div css={heroTextWrapper}>
        <h1 css={title}>Maker Ads Platform</h1>
        <p css={heroText}>
          Advertise each others maker projects on your website and get instant traffic! Win-win situation!
        </p>
        <p css={heroText}>This is a new unique alternative to Google AdSense.</p>
      </div>
    </div>
    <div css={secondSection}>
      <h2 css={H2}>How does it work?</h2>
      <Box m="2rem auto" maxWidth="72rem" display="flex" justifyContent="space-between">
        <div css={card}>Create an account</div>
        <div css={card}>Inject custom code into your HTML</div>
        <div css={card}>You're done and can now check your traffic</div>
      </Box>
    </div>
    <div css={thirdSection}>
      <img src={human} alt="human walking" />
      <Box mt="2rem">
        <div css={blobContent}>
          <p>The following iframe code can be injected wherever you want in your HTML file.</p>
          <pre css={blobCard}>{codeText}</pre>
        </div>
        <img src={blob} />
      </Box>
    </div>
    <div css={fourthSection}>
      <Box display="flex" flexDirection="column" justifyContent="center">
        <p css={exampleText}>Below is an example of an ad banner shown on your website</p>
        <a css={previewAd} href="https://makertraffic.com" target="_blank" rel="noopener noreferrer">
          <h1 css={adTitle}>Maker Traffic</h1>
          <h2 css={adDescription}>
            Advertise each others maker projects on your website and get instant traffic! Win-win situation!
          </h2>
        </a>
      </Box>
      <Box mr="3rem">
        <img src={statistic} alt="statistics" />
      </Box>
    </div>
    <div css={footerSection}>
      <div css={footerContent}>
        <Box display="flex" flexDirection="column">
          <span css={footerTitle}>Maker Traffic</span>
          <p css={footerLink}>Contact us any time on Twitter for info, questions etc.</p>
          <p css={footerLink}>You can click on the Twitter handles to the right.</p>
          {/* <a css={footerLink}>Terms of use</a>
          <a css={footerLink}>Privacy policy</a> */}
        </Box>
        <Box display="flex" flexDirection="column">
          <span css={footerTitle}>Created by</span>
          <a css={footerLink} href="https://twitter.com/frederikbolding">
            @frederikbolding
          </a>
          <a css={footerLink} href="https://twitter.com/deamlabs">
            @deamlabs
          </a>
        </Box>
        <Box display="flex" flexDirection="column">
          <span css={footerTitle}>Credits</span>
          <a css={footerLink} href="https://undraw.co/">
            undraw.co
          </a>
          <a css={footerLink} href="https://www.humaaans.com/">
            humaaans.com
          </a>
        </Box>
      </div>
    </div>
  </Layout>
)

export default IndexPage
